//######################## html default tag-classes ####################################################################
body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    line-height: 105%;
}

a {
    transition: all .2s ease-in-out;
}

a:link {
    color: #000000;
}

a:visited {
    color: #000000;
}

a:hover {
    text-decoration: none;
    transition: all .4s ease-in-out;
    color: #5f5f5f;
}

a:active {
    color: #000000;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

//######################## scrollbar classes ###########################################################################
::-webkit-scrollbar-track {
    box-shadow: none;
    background: #e3e3e3;
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    background: #2c2c2f;
    border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
    background: #5b6363;
}

//######################## custom classes ##############################################################################
.flex {
    display: flex;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.form-part-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2rem 0;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15rem;

    .login-error {
        font-size: 0.9rem;
        width: 42rem;
        height: 3rem;
    }

    .login-card{
        display: flex;
        font-size: 1.2rem;
        width: 42rem;
        height: 20rem;

        .login-header{
            background-color: #ec6411;
            color: #fff;
        }
    }
}

.forbidden, .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4rem;

    .forbidden__footer, .not-found__footer {
        margin-bottom: 2rem;
    }
}

//######################## addon form classes ##########################################################################
.z-index {
    z-index: 999;
}

.bg-blue {
    background-color: #104366;
}

.height-60 {
    height: 60px;
}

.height-42 {
    height: 42px;
}

.width {
    width: 400px;
}

.font-color-white {
    color: #ffffff;
}

.addon-list-header-cell {
    font-weight: bold;
    color: #000000;
    opacity: 1 !important;
    background: #ffffff !important;
    border-style: none;
}

.addon-list-default-template {
    input:first-of-type {
        position: static;
        margin-right: 6px;
    }
}

.flex-flow-wrap {
    display: flex;
    flex-flow: row wrap;
    max-width: 1500px;
    margin: auto;

    .item-100 {
        width: 100%;
        padding:  0 0 0 1.5rem;

        .create-record-button {
            margin: 2.5rem 2rem 0 0;
        }

        .domain-path-form {
            width: 100%;

            .domain-path-form-element {
                width: 48%;
                padding: 0 .25rem;
            }

            .domain-path-form-button {
                width: 4%;
            }
        }

        .access-data-form {
            width: 100%;

            .access-data-form-element {
                width: 24%;
                padding: 0 .25rem;
            }

            .access-data-form-button {
                width: 4%;
            }

            .access-data-textarea-height {
                min-height: 3rem;
                max-height: 200px;
            }
        }

        .file-upload-alert {
            width: 32%;
            margin-top: 1rem;
        }

        .upload-attachment {
            width: 32%;
            margin: 2rem 0;

            button {
                padding: 0;
            }

            span {
                padding: 0 0 0 .5rem;
            }
        }

        .further-information-form {
            width: 100%;

            .further-information-form-element {
                width: 48%;
                padding: 0 .25rem;

                .furhter-information-input-textarea-height {
                    height: 17rem;
                }
            }

            .further-information-hidden-placeholder {
                width: 4%;
            }
        }
    }

    .item-50 {
        width: 50%;
        padding:  0 1.5rem;

        .background-countries {
            margin: 0 0 1rem 0;
            background-color: #f0ffff;
        }

        .margin-countries {
            margin: 1rem 0.5rem 1rem 1rem;
        }

        .padding-countries {
            height: 2rem;
            padding: 0.5rem 0.5rem 1rem 0;
        }

        .input-textarea-height {
            min-height: 6.3rem;
            max-height: 200px;
        }

        .input-margin-left {
            margin-left: -0.53rem;
        }

        .input-margin-right {
            margin-right: -0.53rem;
        }

        .input-number {
            width: 7rem;
        }
    }

    .attachment-form {
        width: 100%;
        padding-right: 1.5rem;

        td {
            width: 35%;
        }

        .delete-attachment {
            width: 100%;
            padding-right: 0;
            justify-content: flex-end;
        }
    }
}

//######################## addon list classes ##########################################################################
.height-600 {
    min-height: 600px;
}

.addon-list-template-fields {
    height: 15rem;
    width: 25rem;

    .addon-list-template-checkboxes {
        height: 10rem;
        overflow: auto;

        .form-check-input {
            position: absolute;
            margin-top: 0;
            margin-left: -1.25rem;
        }
    }
}

.addon-list-template-message {
    margin-top: 0.2rem;
    width: 70%;
    padding: .2rem 0.7rem !important;
}

.addon-list-template-container {
    height: 15rem;
    width: 40rem;

    .addon-list-template-form {
        height: 13rem;
        width: 50%;
        margin-right: 1rem;
    }

    .addon-list-template-list {
        height: 10rem;
        width: 50%;
        margin: 2.5rem 0 0 1rem;
        overflow: auto;

        .form-check-input {
            position: absolute;
            margin-top: .1rem;
            margin-left: -1.25rem;
        }
    }
}

.addon-list-cell {
    min-width: 180px;
    max-width: 180px;
}

.addon-list-cell__consecutive-addon-id {
    min-width: 100px;
    max-width: 100px;
}

.addon-list-cell__react-select {
    min-width: 300px;
    max-width: 300px;
}

.table th, .table td {
    vertical-align: middle;
}

.edit-addon-button {
    border-right: 1px solid #e3e3e3;
    width: 5px;
}

.xlsx-export-checkbox-all {
    border-right: 1px solid #e3e3e3;
    width: 2px;
    vertical-align: middle !important;
}

.xlsx-export-checkbox {
    border-right: 1px solid #e3e3e3;
    width: 2px;
}

.xlsx-export-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

//######################## access data list classes ####################################################################
.access-data-list {
    width: 25rem;

    .margin-filter {
        margin: .4rem 1rem 0 0;
    }
}

.access-data-list__table {
    width: 100%;

    .table__addon-id {
        min-width: 2rem;
        max-width: 2rem;
    }

    .table__addon-name {
        min-width: 7rem;
        max-width: 7rem;
    }

    .table__type {
        min-width: 2rem;
        max-width: 2rem;
    }

    .table__username {
        min-width: 5rem;
        max-width: 5rem;
    }

    .table__password {
        min-width: 5rem;
        max-width: 5rem;
    }

    .table__annotation {
        min-width: 15rem;
    }
}

//######################## react-select custom classes #################################################################
.react-select__control {
    border-style: none !important;
    border-radius: .2rem !important;
    width: 100%;
    min-height: 30px !important;
    max-height: 3.5rem !important;
    overflow: auto;

    .react-select__value-container {
        min-height: 30px !important;
        border: 1px solid #ced4da;
        font-size: .9rem;
        font-weight: normal;

        .react-select__multi-value {
            margin: 1px !important;
        }
    }

    .react-select__placeholder {
        font-weight: 400;
        color: #6c757d;
        top: 0;
    }

    .react-select__indicators {

        .react-select__indicator {
            display: none;
        }

        .react-select__indicator-separator {
            display: none;
        }
    }
}

.react-select__menu {
    font-size: 1rem;
    font-weight: normal;

    .react-select__menu-list {

        .react-select__option {
            background-color: #ffffff;
            font-size: .9rem;
            padding: 0 0 .3rem 1rem !important;
        }
        .react-select__option:hover {
            background-color: #e3e3e3;
        }

        .react-select__option:active {
            background-color: #e3e3e3;
        }
    }
}

.planned-countries__control {
    border-style: none !important;
    border-radius: .2rem !important;
    width: 100%;
    min-height: 2.2rem !important;
    max-height: 2.2rem !important;
    overflow: auto;

    .planned-countries__value-container {
        min-height: 2.2rem !important;
        border: 1px solid #ced4da;
        font-size: .9rem;
        font-weight: normal;
    }

    .planned-countries__placeholder {
        font-weight: 400;
        color: #6c757d;
        top: 0;
    }

    .planned-countries__indicators {
        border: .002rem solid  #ced4da;
        border-radius: .2rem !important;

        .planned-countries__dropdown-indicator {
            display: none !important;
        }

        .planned-countries__indicator-separator {
            display: none;
        }
    }
}

.planned-countries__menu {
    font-size: 1rem;
    font-weight: normal;

    .planned-countries__menu-list {

        .planned-countries__option {
            background-color: #ffffff;
            font-size: .9rem;
            padding: 0 0 .3rem 1rem !important;
        }

        .planned-countries__option:hover {
            background-color: #e3e3e3;
        }

        .planned-countries__option:active {
            background-color: #e3e3e3;
        }
    }
}

.planned-countries__option-label {
    display: flex;

    .option-label__usages {
        margin: 0 0 0 10px;
        color: #aaaaaa;
    }
}

.mobile-phones__control {
    border-style: none !important;
    border-radius: .2rem !important;
    width: 100%;
    min-height: 2.2rem !important;
    max-height: 2.2rem !important;
    overflow: auto;

    .mobile-phones__value-container {
        min-height: 2.2rem !important;
        border: 1px solid #ced4da;
        border-right: none;
        font-size: .9rem;
        font-weight: normal;
    }

    .mobile-phones__placeholder {
        font-weight: 400;
        color: #6c757d;
        top: 0;
    }

    .mobile-phones__indicators {
        border: .09rem solid  #ced4da;
        border-left: none;

        .mobile-phones__dropdown-indicator {
            color: #343a40;

            svg {
                width: 16px;
                position: absolute;
                right: 0;
                top: 7px;
            }
        }

        .mobile-phones__indicator-separator {
            display: none;
        }
    }
}

.mobile-phones__menu {
    font-size: 1rem;
    font-weight: normal;

    .mobile-phones__menu-list {

        .mobile-phones__option {
            background-color: #ffffff;
            font-size: .9rem;
            padding: 0 0 .3rem 1rem !important;
        }

        .mobile-phones__option:hover {
            background-color: #e3e3e3;
        }

        .mobile-phones__option:active {
            background-color: #e3e3e3;
        }
    }
}

.mobile-phones__option-label {
    display: flex;

    .option-label__manufacturer {
        margin: 0 4px 0 0;
    }

    .option-label__usages {
        margin: 0 0 0 10px;
        color: #aaaaaa;
    }
}